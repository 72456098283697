import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Layout from "../layout"
import ComponentParser from "../ComponentParser"
import axios from "axios"
import ReactMarkdown from "react-markdown"
import Seo from "gatsby-plugin-wpgraphql-seo"
import "./PageStatic.scss"

const PageStatic = ({ data, pageContext }) => {
  const [markdown, setMarkDown] = useState()
  const { wpPage: page } = data

  useEffect(() => {
    const getMarkdownData = async () => {
      if (page.ACFContentURL && page.ACFContentURL.contentUrl) {
        const {
          data: { content },
        } = await axios.get(page.ACFContentURL.contentUrl)
        setMarkDown(content)
      }
    }
    getMarkdownData()
  }, [])

  return (
    <Layout
      menuColor={page.whitemenucheckbox.whiteMenu}
      hideNavigation={page.whitemenucheckbox.hideNavigation}
      cleanFooter={page.whitemenucheckbox.cleanFooter}
      whiteBackgroundMenu={page.whitemenucheckbox.whiteBackgroundMenu}
    >
      <Seo post={page} />
      <div className="page page-static">
        <ComponentParser content={page.blocks} />

        {!!markdown && (
          <div className="container">
            <div className="page-static__content">
              <ReactMarkdown>{markdown}</ReactMarkdown>
            </div>
          </div>
        )}
      </div>
    </Layout>
  )
}

PageStatic.propTypes = {
  data: PropTypes.object.isRequired,
}

export default PageStatic

export const pageQuery = graphql`
  query PageStaticById($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      ACFContentURL {
        contentUrl
      }
      whitemenucheckbox {
        whiteMenu
        hideNavigation
        hideButton
        cleanFooter
        whiteBackgroundMenu
      }
      blocks {
        ... on WpAcfTeaserBlock {
          ACFTeaser {
            title
            subtitle
            content
            lottie {
              mediaItemUrl
            }
            button {
              title
              url
            }
          }
        }
        originalContent
        innerBlocks {
          originalContent
        }
      }
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            extension
            publicURL
          }
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            extension
            publicURL
          }
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
    }
  }
`
